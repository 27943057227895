import React from "react";
import Layout from "../components/layout";
import OfficeBtn from "../components/officeBtn";

const Marketing = () => {
  return (
    <Layout>
      <div className="container">
        <h1 className="text-center mt-60 mb-30">Отдел Маркетинга</h1>
        <OfficeBtn />
        <div className="region">
          <div className="hr">
            <div className="region__position">----------</div>
            <ul>
              <li className="region__fio">Куликов Александр Анатольевич</li>
              <li> — офис: +7 (812) 640-30-30, доб. 727, </li>
              <li> — сотовый: +7-906-247-30-53</li>
              <li> — e-mail: kulikov@dendor.ru</li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Marketing;
